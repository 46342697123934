module.exports = [{
      plugin: require('../../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-N2XWM5M","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Milan Laser Hair Removal","short_name":"Milan Laser","description":"Laser Hair Removal | Milan Laser is one of the nation’s leading laser hair removal companies. We are committed to providing our clients with world-class customer service and permanent results. Our physicians and highly-trained medical staff utilize ongoing medical research to ensure that all treatments are performed safely and are tailored to give every client the best results possible. Our goal is to provide 100% customer satisfaction to every client and we will do everything we can to ensure our clients are happy with their experience as well as their results.","start_url":"/","background_color":"#F2FAFD","theme_color":"#F2FAFD","display":"standalone","icon":"/opt/build/repo/theme-2023/src/images/favicon.webp","icons":[{"src":"/opt/build/repo/theme-2023/src/images/android-chrome-192x192.webp","sizes":"192x192","type":"image/png"},{"src":"/opt/build/repo/theme-2023/src/images/android-chrome-256x256.webp","sizes":"256x256","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d86e0a17ee6e0d2e70fbdb8db6f8086c"},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../theme-2023/gatsby-browser.js'),
      options: {"plugins":[],"siteData":{"openStores":1,"google_analytics_id":"UA-32839370-71","stores":[{"cm_pardot_file":"76 - 100","geo":{"lat":"41.626973","long":"-85.909729"},"stateAbbrev":"IN","addressCity":"Goshen","state":"Indiana","description":{"line_one":"directly off Elkhart Road","line_two":", behind Meijer, and next to Salon Centric"},"rating":5,"address":"4542 Elkhart Rd STE 600","open":true,"pathname":"elkhart","maps":{"apple":"https://maps.apple.com/?address=4542%20Elkhart%20Rd,%20Unit%20600,%20Goshen,%20IN%20%2046517,%20United%20States&auid=12892391630171986890&ll=41.626976,-85.909718&lsp=9902&q=Milan%20Laser%20Hair%20Removal&_ext=CjIKBQgEEOIBCgQIBRADCgQIBhBnCgQIChAACgQIUhABCgQIVRAQCgQIWRAGCgUIpAEQARImKSzHdY6tz0RAMY3S5kWbelXAOaqcm+rT0ERAQS33d1/WeVXAUAQ%3D","google":"https://www.google.com/maps/place/Milan+Laser+Hair+Removal/@41.6276167,-85.9104133,15z/data=!4m5!3m4!1s0x0:0x6a52350cce2d24d9!8m2!3d41.6276167!4d-85.9104133?shorturl=1"},"hero":{"position":"center","reversed":true},"salesforceValue":"Elkhart","with_pro_images":true,"marketingCity":"Elkhart","reviewCount":43,"clinicName":"","platforms":{"reviews":{"slide_token":"HZENScZPgUcZD9w60EhMigIWOIQY3XpEtY0DLI1HEWWX7IVaT0","list_token":"JYcU5tlwHvZT7qILvkONp1OxyPcLkMKkJpn0tjnnumCuRetVrL"},"google":"https://www.google.com/search?q=milan+laser+hair+removal+4542+elkhart+rd+elkhart+in&oq=Milan+Laser+&aqs=chrome.0.69i59l3j69i57j69i59j69i60l3.2890j0j4&sourceid=chrome&ie=UTF-8#lrd=0x8816e9d4de586ae3:0x6a52350cce2d24d9,3,,,","facebook":"Elkhart","yelp":"goshen"},"openDate":{"seconds":1663563600,"nanoseconds":0},"place_id":"ChIJ42pY3tTpFogR2SQtzgw1Umo","zipCode":"46517","phone":"574-523-3001"}],"origin":"https://milanlaserelkhart.com","grandOpening":false,"pathname":"elkhart","gtag_id":"GTM-N2XWM5M","stateAbbrev":"IN","metroPath":"elkhart","bing_id":"32599581","state":"Indiana","name":"Elkhart","google_verification":"4ca_roGZDROtF7hLRkx_NFgzf_eumoqFFSfw27K_6nk"}},
    },{
      plugin: require('../../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
